import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { PiArrowLeftDuotone } from 'react-icons/pi';
import { Card, Text, Box, ActionIcon, Button, Avatar, Flex, Title, Table, ScrollArea } from '@mantine/core';
import AuthContext from '../../contexts/Auth/authContext';
import Summary from '../../components/Orders/Order/Summary';
import Total from '../../components/Orders/Order/Total';
import OrderInfo from '../../components/Orders/Order/OrderInfo';
import ItemMenuRow from './ItemMenuRow';
import { get_categories } from '../../utils/apis/categories';
import { get_products_by_cat } from '../../utils/apis/products';
import { get_order, post_order, update_order, cancel_order } from '../../utils/apis/orders';
import { localOrder } from '../../utils/functions/generateLocalCart';
import { get_tables } from '../../utils/apis/tables'
import { getTotal } from '../../utils/functions/getTotal.js'
import { AlertaPermisos } from '../../components/Alerta/AlertaPermisos';
import AlertContext from '../../contexts/Alert/alertContext';
import QrOrden from '../../components/Modal/QrOrden';
import MenuView from '../../components/Orders/Order/MenuView.jsx';
import { set } from 'lodash';

const Order = () =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const alertContext = useContext(AlertContext);
  const { alerta } = alertContext;
  const navigate = useNavigate();
  let { id } = useParams()
  const [ orderId, setOrderId ] = useState(null);
  const [ authorization, setAuthorization ] = useState(false);
  const [ showQr, setShowQr ] = useState(false);
  const [ categories, setCategories ] = useState();
  const [ tabIndex, setTabIndex ] = useState('local')
  const [ totalPayment, setTotalPayment ] = useState(0.00);
  const [ category, setCategory ] = useState();
  const [ reloadCart, setReloadCart ] = useState(false);
  const [ products, setProducts ] = useState();//listado de products disponibles
  const [ loadingProducts, setLoadingProducts ] = useState(true)
  const [ orderProducts, setOrderProducts ] = useState([]);
  const [ order, setOrder ] = useState([]);
  const [ tables, setTables ] = useState();
  const [ showProducts, setShowProducts ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ orderInfo, setOrderInfo ] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });

  const goBack = async () =>
  {
    loadCart()
    navigate(-1)
  }

  const getTables = async () =>
  {
    const response = await get_tables();
    setTables(response.results)
  }
  const loadCart = async () =>
  {
    setLoadingProducts(true);

    setReloadCart(false);
    if (orderId) {
      setLoadingProducts(false);
    }
  }

  const chanegOrderInfo = (field, e) =>
  {
    setOrderInfo(prevState => ({ ...prevState, [ field ]: e }));
  }


  const handleTabsChange = (index) =>
  {
    console.log('INDEX ', index)
    setTabIndex(index)
  }

  const getOrder = async (id) =>
  {
    setLoadingProducts(true);
    const res = await get_order(id);
    if (res.results.orderType === 'delivery') {
      setTabIndex('delivery');
    }
    if (res.results.orderType === 'local') {
      setTabIndex('local');
    }
    if (res.results.orderType === 'pickup') {
      setTabIndex('pickup');
    }
    if (res.results.orderType === 'uberEats') {
      setTabIndex('uberEats');
    }
    if (res.results.orderType === 'rappi') {
      setTabIndex('rappi');
    }
    if (res.results.orderType === 'didi') {
      setTabIndex('didi');
    }
    setOrderInfo(res.results.orderInfo);
    console.log('ORDER ', res.results.orderProducts)
    const items = res.results.orderProducts;
    const order = await localOrder(items)
    setOrderProducts(order)
  }
  useEffect(() =>
  {
    (async () =>
    {
      setLoadingProducts(true);
      await getAllCategories();
      loadCart()
      getTables();

      setTotalPayment(getTotal(orderProducts))
      if (!id) {
        setLoadingProducts(false);
      }
    })()
  }, []);

  useEffect(() =>
  {
    (async () =>
    {
      if (id) {
        setLoadingProducts(true);
        setOrderId(id)
        await getOrder(id)
        loadCart()
        setAuthorization(true)
      }
    })()
  }, [ id ]);

  useEffect(() =>
  {
    (async () =>
    {
      setLoadingProducts(true);
      loadCart()
      getTables();
      setTotalPayment(getTotal(orderProducts))
      setLoadingProducts(false);
    })()
  }, [ reloadCart ]);

  const getAllCategories = async () =>
  {
    const res = await get_categories();
    setCategories(res.results)
    setCategory(res.results[ 0 ]._id);
    getAllProductsByCategory(res.results[ 0 ]._id);
  }

  const changeCategory = async (id) =>
  {
    setCategory(id);
    getAllProductsByCategory(id);
    setShowProducts(true);
  }

  const getAllProductsByCategory = async (cat) =>
  {
    const res = await get_products_by_cat(cat);
    setProducts(res.results);
  }

  const generateOrder = async () =>
  {

    let orderType = tabIndex;

    let data = {
      orderInfo,
      orderType,
      orderProducts,
      total: totalPayment
    }

    return data
  }



  const putNewOrder = async () =>
  {
    setLoading(true);
    const data = await generateOrder();
    const res = await post_order(data);
    if (res.msg === 'done') {
    setLoading(false);

      goBack()
    }
    setLoading(false);

  }

  const updateOrder = async () =>
  {
    setLoading(true);
    const data = await generateOrder()
    const res = await update_order(orderId, data)

    if (res.msg === 'done') {
      setLoading(false);
      goBack()
    }
    setLoading(false);
  }

  const cancel = () =>
  {
    const data = {
      orderProducts
    }
    cancel_order(orderId, data);
    goBack();
  }

  const newAddProduct = (id, product, quantity, extras = []) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Busca si el producto ya existe en orderProducts
    const existingProductIndex = updatedOrderProducts.findIndex(p => p.id === id);

    if (existingProductIndex !== -1) {
      // Si el producto ya existe, actualiza la cantidad
      updatedOrderProducts[ existingProductIndex ].quantity += quantity;

      // Actualiza los extras, añadiendo o actualizando la cantidad de los mismos
      extras.forEach(extra =>
      {
        const existingExtraIndex = updatedOrderProducts[ existingProductIndex ].extras.findIndex(e => e.id === extra.id);
        if (existingExtraIndex !== -1) {
          // Si el extra ya existe, actualiza su cantidad
          updatedOrderProducts[ existingProductIndex ].extras[ existingExtraIndex ].quantity += extra.quantity;
        } else {
          // Si el extra no existe, agrégalo con su cantidad
          updatedOrderProducts[ existingProductIndex ].extras.push({
            id: extra.id,
            name: extra.name,
            price: extra.price,
            quantity: extra.quantity
          });
        }
      });
    } else {
      // Si el producto no existe, agrégalo al array con sus extras
      updatedOrderProducts.push({
        id,
        _id: product._id,
        name: product.name,
        price: product.price,
        statusProductOrder: false,
        isCourtesy: false,
        quantity,
        extras: extras.map(extra => ({
          id: extra.id,
          name: extra.name,
          price: extra.price,
          quantity: extra.quantity
        }))
      });
    }

    // Actualiza el estado con la nueva copia del array orderProducts
    setOrderProducts(updatedOrderProducts);
  };


  const newIncreaseProduct = (productId) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(
      (product) => product.id === productId
    );

    if (productIndex !== -1) {
      // Incrementa la cantidad del producto
      updatedOrderProducts[ productIndex ].quantity += 1;

      // Actualiza el estado con la nueva copia del array orderProducts
      setOrderProducts(updatedOrderProducts);
    }
  };


  const newDecreaseProduct = (productId) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(
      (product) => product.id === productId
    );

    if (productIndex !== -1) {
      // Disminuye la cantidad del producto
      updatedOrderProducts[ productIndex ].quantity -= 1;

      // Si la cantidad llega a 0, elimina el producto del array
      if (updatedOrderProducts[ productIndex ].quantity <= 0) {
        updatedOrderProducts.splice(productIndex, 1);
      }

      // Actualiza el estado con la nueva copia del array orderProducts
      setOrderProducts(updatedOrderProducts);
    }
  };

  const removeProduct = (productId) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(
      (product) => product.id === productId
    );

    if (productIndex !== -1) {
      // Elimina el producto del array
      updatedOrderProducts.splice(productIndex, 1);

      // Actualiza el estado con la nueva copia del array orderProducts
      setOrderProducts(updatedOrderProducts);
    }
  };

  const removeExtraFromProduct = (productId, extraId) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(product => product.id === productId);

    if (productIndex !== -1) {
      // Encuentra el índice del extra en el array de extras del producto
      const extraIndex = updatedOrderProducts[ productIndex ].extras?.findIndex(extra => extra._id === extraId);

      if (extraIndex !== -1) {
        // Elimina el extra del array de extras del producto
        updatedOrderProducts[ productIndex ].extras.splice(extraIndex, 1);

        // Actualiza el estado con la nueva copia del array orderProducts
        setOrderProducts(updatedOrderProducts);
      }
    }
  };


  const addNoteToProduct = (productId, note) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(
      (product) => product.id === productId
    );

    if (productIndex !== -1) {
      // Agrega la nota al producto
      updatedOrderProducts[ productIndex ].note = note;

      // Actualiza el estado con la nueva copia del array orderProducts
      setOrderProducts(updatedOrderProducts);
    }
  };

  const markCourtesy = (productId) =>
  {
    // Crea una copia del array orderProducts para evitar modificar el estado directamente
    const updatedOrderProducts = [ ...orderProducts ];

    // Encuentra el índice del producto en el array
    const productIndex = updatedOrderProducts.findIndex(
      (product) => product.id === productId
    );

    if (productIndex !== -1) {
      // Agrega la nota al producto
      updatedOrderProducts[ productIndex ].isCourtesy = !updatedOrderProducts[ productIndex ].isCourtesy;
      updatedOrderProducts[ productIndex ].price = 0.00;

      // Actualiza el estado con la nueva copia del array orderProducts
      setOrderProducts(updatedOrderProducts);
    }
  };
  const clearOrder = () =>
  {
    setOrderProducts([]);
  }


  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col md:flex-row items-start sm:items-center justify-start sm:justify-between w-full'>
        <div className='flex flex-col sm:flex-row items-start md:items-center justify-start sm:justify-between  md:justify-start gap-2 mb-4 sm:md-0 w-full'>
          <Flex direction='row' gap="md" align='center'>
            <ActionIcon size={ 'md' } color='gray' variant='subtle' aria-label='Regresar' onClick={ () => goBack() } >
              <PiArrowLeftDuotone className='text-xl' />
            </ActionIcon>
            <Title order={ 1 }>{ orderId ? `Editar pedido` : 'Crear nuevo pedido' }</Title>
          </Flex>

        </div>
        <div className='flex flex-row gap-2 items-center w-full md:w-4/12'>
          {/* <Button variant="light" fullWidth color="indigo" size='md' radius="md" onClick={ () => setShowQr(!showQr) }>
            <Text>Mostrar Qr</Text>
          </Button> */}
          { user.role.includes('CASHIER') ?
            <>
              { orderId && <Button fullWidth variant="filled" size='md' radius="md" color='red' disabled={ orderProducts.length === 0 } onClick={ () => cancel() }>Cancelar</Button> }
            </>
            :
            null
          }

          { !orderId && <Button fullWidth loading={loading}  variant="filled" size='md' radius="md" color='blue' disabled={ orderProducts.length === 0  | loading} onClick={ () => putNewOrder() }>Enviar</Button> }
          { orderId && <Button fullWidth loading={loading}   variant="filled" size='md' radius="md" color='blue' disabled={ orderProducts.length === 0 | loading } onClick={ () => updateOrder() }>Actualizar</Button> }
        </div>
      </div>
      <Card withBorder shadow='md' py="lg" px="lg" radius="xl">
        <OrderInfo orderInfo={ orderInfo } chanegOrderInfo={ chanegOrderInfo } tables={ tables } tabIndex={ tabIndex } handleTabsChange={ handleTabsChange } />
      </Card>
      <div className='flex flex-col-reverse  md:flex-col lg:flex-row gap-4'>
        <Card withBorder shadow='md' my="md" radius="xl" px="lg" className='w-full overflow-auto'>
          <ScrollArea className='mb-2'>

            <MenuView categories={ categories } changeCategory={ changeCategory } selected={ category } />
          </ScrollArea>

          <ScrollArea className='w-full min-h-fit'>
            <Table>
              <Table.Tbody>
                { products && products.map((product) => (
                  <ItemMenuRow key={ product._id } product={ product } addProduct={ newAddProduct } reloadCart={ setReloadCart } />
                )) }
              </Table.Tbody>
            </Table>
          </ScrollArea>

        </Card>
        <div className='w-12/12 lg:w-5/12 flex flex-col gap-2'>
          <Card className='hidden md:block' withBorder shadow='md' my="sm" radius="xl">
            <Total total={ totalPayment } />
          </Card>
          <Summary
            authorization={ authorization }
            order={ order }
            reloadCart={ setReloadCart }
            orderProducts={ orderProducts }
            setOrderProducts={ setOrderProducts }
            increase={ newIncreaseProduct }
            decrease={ newDecreaseProduct }
            addNote={ addNoteToProduct }
            clearOrder={ clearOrder }
            loadingProducts={ loadingProducts }
            setLoadingProducts={ setLoadingProducts }
            setTotalPayment={ setTotalPayment }
            removeProduct={ removeProduct }
            getTotal={ getTotal }
            markAsCourtesy={ markCourtesy }
            removeExtraFromProduct={ removeExtraFromProduct }
          />
          <Card className='block md:hidden' withBorder shadow='md' my="sm" radius="xl">
            <Total total={ totalPayment } />
          </Card>


        </div>
      </div>
      { alerta && <AlertaPermisos alerta={ alerta } /> }
      { showQr && <QrOrden show={ showQr } setShow={ setShowQr } orderId={ orderId } /> }
    </div>
  )
}

export default Order