import BoxSummary from "./BoxSummary"
import dayjs from "dayjs"
import { Flex, Divider, Title, } from "@mantine/core"
const MonthlySummary = ({ dateStart, monthlyOrderType, monthlySummary }) =>
{
  return (
    <>
      <Flex direction={ { base: 'column', md: 'row' } } justify={ { base: "flex-start" } } align={ { base: 'flex-start', md: "flex-end" } } my="md">
        <Title order={ 2 }>Resumen Mensual</Title>
        <Title order={ 4 } mx={ { base: '', md: 'sm' } } className="capitalize">
          { dayjs(dateStart).locale("es-mx").format("MMMM") }
        </Title>
      </Flex>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mb-2">
        <BoxSummary
          title={ "Efectivo" }
          value={
            monthlySummary.totalCash
              ? `$ ${ monthlySummary.totalCash }`
              : "$ 0.00"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Tarjeta" }
          value={
            monthlySummary.totalCards
              ? `$ ${ monthlySummary.totalCards }`
              : "$ 0.00"
          }
          color="pink"
        />
        <BoxSummary
          title="Total de ventas"
          value={
            monthlySummary.totalEarn
              ? `$ ${ monthlySummary.totalEarn }`
              : "$ 0.00"
          }
          color="blue"
        />
        <BoxSummary
          title={ "Comisión de tarjeta" }
          value={
            monthlySummary.totalCard
              ? `$ ${ monthlySummary.totalCards * 0.04 }`
              : "$ 0.00"
          }
          color="orange"
        />
        <BoxSummary
          title={ "Tarjeta - Comisión" }
          value={
            monthlySummary.totalCards
              ? `$ ${ (monthlySummary.totalCards - (monthlySummary.totalCards * 0.04)) }`
              : "$ 0.00"
          }
          color="yellow"
        />
        <BoxSummary
          title="Total de ventas - comisión"
          value={
            monthlySummary.totalEarn
              ? `$ ${ (monthlySummary.totalEarn - (monthlySummary.totalCards * 0.04)) }`
              : "$ 0.00"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Local" }
          amount={ false }
          value={
            monthlyOrderType?.local.totalTransactions
              ? `${ monthlyOrderType.local.totalTransactions }`
              : "0"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Delivery" }
          amount={ false }
          value={
            monthlyOrderType?.delivery.totalTransactions ? monthlyOrderType?.delivery.totalTransactions : "0"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Pickup" }
          amount={ false }
          value={
            monthlyOrderType?.pickup.totalTransactions
              ? `${ monthlyOrderType.pickup.totalTransactions }`
              : "0"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Uber Eats" }
          amount={ false }
          value={
            monthlyOrderType?.uberEats.totalTransactions
              ? `${ monthlyOrderType.uberEats.totalTransactions }`
              : "0"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Rappi" }
          amount={ false }
          value={
            monthlyOrderType?.rappi.totalTransactions
              ? `${ monthlyOrderType.rappi.totalTransactions }`
              : "0"
          }
          color="gray"
        />
        <BoxSummary
          title={ "Didi" }
          amount={ false }
          value={
            monthlyOrderType?.didi.totalTransactions
              ? `${ monthlyOrderType.didi.totalTransactions }`
              : "0"
          }
          color="gray"
        />
      </div>

      <Divider className="my-8" />
    </>
  )
}

export default MonthlySummary