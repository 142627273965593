import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";

import { Title, Flex, Button, Input, Box, Text, Alert, Card, Divider } from "@mantine/core";
import { PiInfoFill } from 'react-icons/pi'
import
{
  get_all_today_transactions,
  _getDashboardTotalPaymentsByMethods,
  getAllTransactionMonthly,
  get_dashboard_inventory,
  get_dashboard_total_payments_by_methods,
  get_dashboard_users,
} from "../../utils/apis/dashboard";
import { countTransactions } from "../../utils/functions/getCountAndTotal";
import useVaidateExpiredDate from '../../hooks/validateExpiredDate'
import { get_all_orders } from "../../utils/apis/orders";
// import DailyRow from "../../components/Reports/Daily/DailyRow";
import { SocketContext } from "../../contexts/Socket/socket";
import DashboardContext from "../../contexts/Dashboard/dashboardContext";
import { OrderType } from "../../components/Dashboard/OrderType/OrderType";
import { SalesByUser } from "../../components/Dashboard/SalesByUser/SalesByUser";
import { SalesByProducts } from "../../components/Dashboard/SalesByProducts/SalesByProducts";
import { SalesByOrdes } from "../../components/Dashboard/SalesByOrders/SalesByOrdes";
import AuthContext from '../../contexts/Auth/authContext';
import DailyGrid from "../../components/Dashboard/DailyGrid/DailyGrid";
import MonthlySummary from "../../components/Dashboard/MonthlySummary";

const Dashboard = () =>
{
  const { hoy, expired, expiredDate, loadingExpirated } = useVaidateExpiredDate()
  const socket = useContext(SocketContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const dashboardContext = useContext(DashboardContext);
  const { dateStart, dashboardUpdateDate } = dashboardContext;
  const [ completeOrders, setCompleteOrders ] = useState();
  const [ monthlySummary, setMonthlySummary ] = useState({
    totalEarn: 0.0,
    totalCash: 0.0,
    totalCards: 0.0,
  });

  const [ monthlyOrderType, setMonthlyOrderType ] = useState();
  const [ dailyOrderType, setDailyOrderType ] = useState();
  const [ totalEarn, setTotalEarn ] = useState(0.0);
  const [ totalClosed, setTotalClosed ] = useState(0);
  const [ totalOpen, setTotalOpen ] = useState(0);
  const [ byUser, setByUser ] = useState();
  const [ methodAmount, setMethodAmount ] = useState({
    efectivo: 0,
    tarjeta: 0,
  });
  const [ products, setProducts ] = useState(null);
  const [ totalProducts, setTotalProducts ] = useState(0);

  const getTotalProducts = async (items) =>
  {
    let total = 0;

    for (let i = 0; i < items.length; i++) {
      total -= items[ i ].qty;
    }
    return total;
  };



  const getDashboard = async (date) =>
  {
    console.log(date)
    await getMonthlyDetails(date);
    await getDashboardTransactions(date);
    await getDashboardInventory(date);
    await getDashboardTotalPaymentsByMethods(date);
    await getDashboardUsers(date);
  };

  useEffect(() =>
  {
    (async () =>
    {
      dashboardUpdateDate(dayjs().format("YYYY-MM-DD"));
      await getDashboard(dayjs().format("YYYY-MM-DD"));
    })();
  }, []);

  const getDashboardTransactions = async (start) =>
  {
    const res = await get_all_today_transactions({
      dateStart: dayjs(start).hour(6).minute(0).second(0).toISOString(),
    });
    const resOrder = await get_all_orders();
    setDailyOrderType(countTransactions(res?.results?.transactions))
    setCompleteOrders(res.results.transactions);
    // setMonthlyOrderType({
    //   local:res.results.transactions.filter((item) =>
    //   {
    //     return item.orderType === "local";
    //   }),
    //   delivery:res.results.transactions.filter((item) =>
    //   {
    //     return item.orderType === "delivery";
    //   }),
    //   pickup:res.results.transactions.filter((item) =>
    //   {
    //     return item.orderType === "pickup";
    //   })
    // })
    setTotalEarn(res.results?.total);
    setTotalClosed(res.results.totalTransactions);
    setTotalOpen(resOrder.results?.total);

    console.log(monthlyOrderType)
  };
  const getDashboardInventory = async (start) =>
  {
    const res = await get_dashboard_inventory({
      dateStart: dayjs(start).hour(6).minute(0).second(0).toISOString(),
    });
    setProducts(res.results.inventory);
    const total = await getTotalProducts(res.results.inventory);
    setTotalProducts(total);
  };

  const getDashboardTotalPaymentsByMethods = async (start) =>
  {
    const res = await get_dashboard_total_payments_by_methods({
      dateStart: dayjs(start).hour(6).minute(0).second(0).toISOString(),
    });
    const efectivo = res.results.payments.filter((item) =>
    {
      return item._id === "efectivo";
    });
    const tarjeta = res.results.payments.filter((item) =>
    {
      return item._id === "tarjeta";
    });

    setMethodAmount({
      efectivo: efectivo[ 0 ]?.total,
      tarjeta: tarjeta[ 0 ]?.total,
    });
  };

  const getDashboardUsers = async (start) =>
  {
    const res = await get_dashboard_users({
      dateStart: dayjs(start).hour(6).minute(0).second(0).toISOString(),
    });
    setByUser(res.results.users);
  };

  const getMonthlyDetails = async (date) =>
  {
    const res = await getAllTransactionMonthly(date);
    const responses = await _getDashboardTotalPaymentsByMethods(date);
    const efectivo = responses.results?.payments.filter((item) =>
    {
      return item._id === "efectivo";
    });
    const tarjeta = responses.results?.payments.filter((item) =>
    {
      return item._id === "tarjeta";
    });




    setMonthlySummary({
      totalEarn: res?.results?.total,
      totalCash: efectivo ? efectivo[ 0 ]?.total : 0.0,
      totalCards:tarjeta ? tarjeta[ 0 ]?.total : 0.0,
    });
    setMonthlyOrderType(countTransactions(res?.results?.transactions))
  };

  useEffect(() =>
  {
    socket.on("refreshDashboard", async (payload) =>
    {
      await getDashboard(dateStart);
    });
  }, [ socket ]);

  return (
    <div className="flex flex-col mb-4">
      { user.role.includes('ADMIN') && !loadingExpirated && (
        <Box>
          <Alert
            size="sm"
            variant="filled"
            autoContrast
            radius="lg"
            icon={ <PiInfoFill className="w-16 h-16" /> }
            color={
              hoy > expiredDate
                ? "red"
                : hoy === expiredDate
                  ? "yellow"
                  : "blue"
            }
            title="Anuncio"
          >

            { hoy > expiredDate && "Servicio vencido" }
            { hoy === expiredDate && "El servicio vence hoy" }
            { hoy < expiredDate &&
              `El servicio vence el ${ expired }` }
          </Alert>
        </Box>
      ) }
      <>
        { user.role.includes('ADMIN') ? (
          <MonthlySummary dateStart={ dateStart } monthlyOrderType={ monthlyOrderType } monthlySummary={ monthlySummary } />
        ) : null }
      </>

      <div className="flex flex-col md:flex-row gap-1 justify-between items-stretch md:items-end mb-4">
        <Flex direction={ { base: 'column', md: 'row' } } justify={ { base: "flex-start" } } align={ { base: 'flex-start', md: "flex-end" } } my="md">
          <Title order={ 2 }>Resumen diario</Title>
          <Title order={ 5 } mx={ { base: '', md: 'sm' } } className="capitalize">
            { dayjs(dateStart).locale("es-mx").format("DD ") }
            de { dayjs(dateStart).locale("es-mx").format("MMMM") }
          </Title>
        </Flex>


        { user.role.includes('ADMIN') && (
          <>
            <Box className="flex flex-col md:flex-row gap-4 items-stretch md:items-center">
              <Box className="flex flex-row gap-1 items-center">
                <Text>De: </Text>
                <Input
                  type="date"
                  size="lg"
                  variant="filled"
                  radius="md"
                  value={ dateStart }
                  onChange={ (e) => dashboardUpdateDate(e.target.value) }
                />
              </Box>

              <Button
                variant="filled"
                color="blue"
                size="lg"
                radius="md"
                onClick={ () => getDashboard(dateStart) }
              >
                Buscar
              </Button>
            </Box>
          </>
        ) }
      </div>

      <DailyGrid
        totalOpen={ totalOpen }
        totalClosed={ totalClosed }
        totalEarn={ totalEarn }
        totalProducts={ totalProducts }
        totalTransactions={ totalClosed }
        dailyOrderType={ dailyOrderType }
        methodAmount={ methodAmount } />
      <Divider className="my-8" />
      <div className="flex flex-row gap-1 justify-start items-stretch md:items-center mb-4">
        <Title order={ 2 }>Ventas del día</Title>
      </div>
      <Box className="flex flex-col-reverse md:flex-row justify-between gap-2">
        <Card
          className="w-12/12 md:w-7/12"
          px="md"
          withBorder
          radius="md"   
        >
          <SalesByOrdes completeOrders={ completeOrders } />
        </Card>
        <Box className="w-12/12 md:w-5/12 flex flex-col gap-2">
          <SalesByUser byUser={ byUser } />
          <OrderType dailyOrderType={ dailyOrderType } />
          <SalesByProducts products={ products } />
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
