import React from 'react'
import { useLocation } from 'react-router-dom'

import { Text, Box, NavLink, Tooltip } from '@mantine/core'

const MenuItem = ({ title, icon, link, showText }) =>
{
  const location = useLocation()
  return (

      <NavLink

        active={ location.pathname === `${ link }` }
        autoContrast
        className="rounded-lg"
        px="sm"
        py="sm"
        variant="filled"
        href={ `#${ link }` }
        label={ showText ? title : null }
        leftSection={ icon }
      />
  )
}

export default MenuItem