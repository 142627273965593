import { Table, Text, Title } from '@mantine/core';
import { NumericFormat } from 'react-number-format';


export const RowOrderType = ({ name, totalTransactions, totalAmount }) =>
{
  return (
    <Table.Tr>
      <Table.Td className="w-4/12" px="sm" py="sm">
        <Text noOfLines={ 1 } className="text-sm md:text-lg">
          {name}
        </Text>
      </Table.Td>
      <Table.Td className="w-3/12 text-center">
        <Text className="text-sm md:text-lg font-bold text-center">
          {totalTransactions }
        </Text>
      </Table.Td>
      <Table.Td className="w-5/12 text-right">
        <Text className="text-sm md:text-lg font-bold text-right">
        <NumericFormat
          value={ totalAmount }
          displayType={ 'text' }
          thousandSeparator={ true }
          prefix={ '$ ' }
          decimalScale={ 2 }
          fixedDecimalScale={ true }
        />
        </Text>
      </Table.Td>
      </Table.Tr>
  )
}
